<template>
</template>
<script>

  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'

  const ethDomain = ApiConfig.ethDomain

  export default {
    name: 'UtopiaAdminLogin',

    created : function() {
      const vm = this
      let router = this.$router
      let userId = this.$route.query.email.replaceAll("'","")

      let failBoolean = true


      const loginParams = {
        userId: userId,
        loginRequestType : "UTOPIA",
        wmGbn: '11', //사용자 웹 01, 사용자 모바일 02, 관리자 웹 11, 관리자 모바일 12
      }

      ApiUtil.post(`${ethDomain}/v1/eth/login.json`, loginParams).then(function (response) {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          failBoolean = false
        }

        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          failBoolean = false
        }

        if(!failBoolean) router.push({name: 'AdminLogin'})

        /**
         * 로그인 성공 시점
         */
        let adminLoginUserData = response.data.resultData
        let adminLoginUserToken = adminLoginUserData.loginUserToken

        const firstAccessParams = {
          loginUserToken: adminLoginUserToken,
          wmGbn: '11', //사용자 웹 01, 사용자 모바일 02, 관리자 웹 11, 관리자 모바일 12
          responseMenuYn: 'Y',
        }

        ApiUtil.post(`${ethDomain}/v1/eth/user/firstAccessInfo.json`, firstAccessParams).then(function (response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            failBoolean = false
          }

          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            failBoolean = false
          }

          if(!failBoolean) router.push({name: 'AdminLogin'})

          /**
           * firstAccessInfo 데이터 가져온 시점
           */
          let firstAccessInfoResultData = response.data.resultData

          sessionStorage.setItem('adminLoginUserToken', adminLoginUserToken)
          sessionStorage.setItem('adminLoginUserData', JSON.stringify(firstAccessInfoResultData))
          sessionStorage.setItem('adminUserInfo', JSON.stringify(adminLoginUserData))

          let authCd = response.data.resultData.authCd

          setTimeout(function () {
            let urlNm = ''
            switch (authCd){
              case '01':
                urlNm = 'PayrollNewsLetterManagement'
                break
              case '02':
                urlNm = 'SharedserviceNewsLetterManagement'
                break
              case '99':
                urlNm = 'AdminDashboard'
                break
              default:
                urlNm = 'HomeQnaManagement'
                break
            }

            router.push(
                {
                  name: urlNm,
                },
            )
          }, 500)
        })
      })


    },
    mounted : function() {
    },
    computed: {},
    methods: {},
    data : function() {
      return {
      }
    },
  }
</script>
<style>

</style>
