<template>
  <div id="container_home" class="eng">
    <div class="company_visual">
      <!-- 스크롤 효과 -->
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <!-- END 스크롤 효과 -->
      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          ABOUT
        </h2>
        <h2 class="company_tit animated d-2s">
          As a management support platform company, it contributes to strengthening the competitiveness of the company by providing management support work as a platform service centered on personnel and general affairs.
        </h2>
      </div>
      <div class="company_img animated d-3s">
        <img src="@/assets/img/company_01.png" class="animated d-1s" alt="">
      </div>
    </div>
    <!--비즈니스플랫폼-->
    <div class="sub_wrap">
      <div class="company_inner animated">
        <div class="vision_title animated d-1s"> BRAND VALUE </div>
        <div class="vision_sub animated d-2s">We, ETNERS, provide innovative Business Support Platform that enhance client values.

<div class="hide_1199"> </div>
With 25 years experience since 1998, we realize customer impressions through standardization of the method of work in HR·GA, Management support field.
</div>
      </div>
      <!-- 브랜드가치--->
      <div class="inner it_interval">
        <div class="vision_box clearfix animated">
          <div class="item">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="box bv1 animated d-1s">
                      <b>Client</b> We provide the best quality of product and service which can increase the value of customer by proactively identifying client needs.
                    </div>
                  </td>
                  <td>
                    <div class="box bv2 animated d-2s"><b>Communication</b>We communicate and cooperate to create a first-class cooperate culture that respects each other.
                    </div>
                  </td>
                  <td>
                    <div class="box bv3 animated d-3s">
                      <b>Credit</b>We come closer to our customers based on trust.
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="item r2">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="box bv4 animated d-4s">
                      <b>Creation</b>Based on our experience, we constantly challenge and create new values.
                    </div>
                  </td>
                  <td>
                    <div class="box bv5 animated d-5s">
                      <b>Contribution</b>In order to achieve sustainable growth, we will contribute to society by fulfilling our corporate social responsibilities.
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

      </div>
      <!--//브랜드가치-->
    </div>
    <!--//비즈니스플랫폼-->
   <!--미션-->
   <div class="company_inner animated">
      <div class="vision_title animated d-1s">
        MISSION
      </div>
      <ul class="valuable_list">
        <li class="animated on">
          <div class="text">
            <div class="big_tit animated d-2s">We enhance customer experience based on trust and credibility.</div>
            <ul class="main-business__list animated d-3s">
               <li>
                <!--div class="mb10"><img src="@/assets/img/mission03.png" alt="">
                    </div-->
                <span class="main-business__label">Credibility</span>
                <p class="main-business__txt">We enhance customer experience based on trust and credibility.</p>
              </li>
              <li>
                <!--div class="mb10"><img src="@/assets/img/mission01.png" alt="">
                    </div-->
                <span class="main-business__label">Innovation</span>
                <p class="main-business__txt">We support the creation of new value by process innovation.</p>
              </li>
              <li>
                <!--div class="mb10"><img src="@/assets/img/mission02.png" alt="">
                    </div-->
                <span class="main-business__label">Quality</span>
                <p class="main-business__txt">We support our customers’ sustainable growth with high-quality services.</p>
              </li>
             
            </ul>
          </div>
          <div class="vi_im"><img src="@/assets/img/img_about02.png" alt=""></div>
        </li>
      </ul>
      <div class="vision_title animated d-1s">
        VISION
      </div>
      <ul class="valuable_list">
        <li class="animated on">
          <div class="text">
            <div class="big_tit animated d-2s">Provides an innovative management support platform that contributes to value improvement.</div>
            <ul class="main-business__list animated d-3s">
              <li>
                <span class="main-business__label">People oriented</span>
                <p class="main-business__txt">Respect the diversity of employees and continue the growth and development of the company through cooperation and communication.
</p>
              </li>
              <li>
                <span class="main-business__label">Challenges and Innovation</span>
                <p class="main-business__txt">We will constantly challenge and innovate with expertise, IT skills, and passion.
</p>
              </li>
              <li>
                <span class="main-business__label">Co-growth</span>
                <p class="main-business__txt">We will fulfill our social responsibility as a corporate citizen, not just to generate profits.</p>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="sub_wrap gray_bg">
      <div class="ci_inner">
        <div class="sub_wrap_tit align-c">
          <div class="eg animated">CI&BI</div>
          <div class="sub animated d-1s">
            ETNERS is a combination of eternal, which means ‘forever’, and partners,<div class="hide_1023"></div>which means 'companion’, meaning to be an eternal companion.
          </div>
        </div>
        <div class="ci_logo animated">
          <div class="ci_tit">Symbol Mark</div>
          <div class="logo_box">
            <div class="item item01">
              <div class="img">
                <img src="@/assets/img/ci_logo01_pc.png" class="hide_767" alt="">
                <img src="@/assets/img/ci_logo01.png" class="hide-default show_767" alt="">
              </div>
            </div>
            <div class="item item02">
              <div class="img">
                <img src="@/assets/img/ci_logo02_pc.png" class="hide_767" alt="">
                <img src="@/assets/img/ci_logo02.png" class="hide-default show_767" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="ci_color animated">
          <div class="ci_tit">COLOR</div>
          <div class="sub animated d-1s">
            Orange represents trust and positive energy, and black, which is created by an even mix of colors, represents a perfect professional image.
            We are dedicated to providing all the services that businesses need based on trust since we believe we are the most ideal partner for success.
          </div>
          <ul class="color_box inline_wrap">
            <li class="orange">
              <div class="tit">orange</div>
              <div class="right">
                <table>
                  <tbody>
                    <tr>
                      <th>HEX</th>
                      <td>#FF6D2D</td>
                    </tr>
                    <tr>
                      <th>RGB</th>
                      <td>255/109/45</td>
                    </tr>
                    <tr>
                      <th>CMYK</th>
                      <td>0/71/86/0</td>
                    </tr>
                    <tr>
                      <th>PANTONE</th>
                      <td>Orange 021 U</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li class="gray mr0">
              <div class="tit">Black</div>
              <div class="right">
                <table>
                  <tbody>
                    <tr>
                      <th>HEX</th>
                      <td>#333132</td>
                    </tr>
                    <tr>
                      <th>RGB</th>
                      <td>51/49/50</td>
                    </tr>
                    <tr>
                      <th>CMYK</th>
                      <td>69/64/61/59</td>
                    </tr>
                    <tr>
                      <th>PANTONE</th>
                      <td>P 179-15C</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--//ci 소개 -->
    <!-- 브랜드 소개 -->
    <div class="sub_wrap">
      <div class="company_inner">
        <div class="sub_wrap_tit align-c">
          <div class="eg animated">ETNERS Brand</div>
        </div>
        <ul class="group_info">
          <li class="animated">

            <ul class="group_logo folg inline_wrap">
              <div class="ci_tit">English BI</div>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo01.png" alt="쉐어드서비스"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo02.png" alt="페이롤"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo03.png" alt="비딩"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo04.png" alt="하우징"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo05.png" alt="몰"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo06.png" alt="무빙"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo07.png" alt="릴로케이션"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo08.png" alt="숍"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo09.png" alt="감동타임"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo10.png" alt="efs"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo21.png" alt="esop"></div>

              </li>
            </ul>
            <ul class="group_info">
              <li class="animated">
                <ul class="group_logo folg inline_wrap">
                  <div class="ci_tit">Korean BI</div>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo11.png" alt="쉐어드서비스"></div>
                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo12.png" alt="페이롤"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo13.png" alt="비딩"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo14.png" alt="하우징"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo15.png" alt="몰"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo16.png" alt="무빙"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo17.png" alt="릴로케이션"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo18.png" alt="숍"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo19.png" alt="감동타임"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo20.png" alt="efs"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo22.png" alt="이솝"></div>

                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
const ethDomain = ApiConfig.ethDomain

export default {
  name: 'CompanyOverviewEn',
  created() {
    const vm = this

  },
  mounted() {
    const vm = this
  },
  methods: {
  },
  data: function () {
    const vm = this
    return {

    }
  }
}
</script>
