<template>
  <div id="container_home">
    <div class="company_visual">
      <!-- 스크롤 효과 -->
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <!-- END 스크롤 효과 -->
      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          ABOUT
        </h2>
        <h2 class="company_tit animated d-2s">
          경영지원플랫폼 기업으로서 인사·총무를 중심으로 경영지원 업무를 플랫폼 서비스로 제공하여 기업의 경쟁력 강화에 기여합니다.
        </h2>
      </div>
      <div class="company_img animated d-3s">
        <img src="@/assets/img/company_01.png" class="animated d-1s" alt="">
      </div>
    </div>
    <!--비즈니스플랫폼-->
    <div class="sub_wrap">
      <div class="company_inner animated">
        <div class="vision_title animated d-1s"> BRAND VALUE </div>
        <div class="vision_sub animated d-2s">
          <div class="hide_1199"> </div>
          1998년부터 25년 간 쌓아온 경험을 바탕으로
          인사 · 총무 경영지원 분야의 일하는 방식을 표준화하여
          클라이언트의 고객 감동을 실현합니다.
        </div>
      </div>
      <!-- 브랜드가치--->
      <div class="inner it_interval">
        <div class="vision_box clearfix animated">
          <div class="item">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="box bv1 animated d-1s">
                      <b>Client</b>클라이언트의 니즈를 선제적으로
                      파악하여 고객의 가치를 높일 수 있는 최고의 제품과 서비스를 제공합니다.
                    </div>
                  </td>
                  <td>
                    <div class="box bv2 animated d-2s"><b>Communication</b>서로를 존중하는 1 등 기업문화를
                      만들기 위해 소통하고
                      협력합니다.
                    </div>
                  </td>
                  <td>
                    <div class="box bv3 animated d-3s">
                      <b>Credit</b>신뢰를 바탕으로 고객에게
                      한 발 더 가까이 다가갑니다.
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="item r2">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="box bv4 animated d-4s">
                      <b>Creation</b>경험과 노하우를 바탕으로
                      끊임없이 도전하여 새로운 가치를
                      창출합니다.
                    </div>
                  </td>
                  <td>
                    <div class="box bv5 animated d-5s">
                      <b>Contribution</b>지속성장을 위해 사회적 책임을
                      다하여 사회발전에
                      기여하겠습니다.
                    </div>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

      </div>
      <!--//브랜드가치-->
    </div>
    <!--//비즈니스플랫폼-->
    <!--미션-->
    <div class="company_inner animated">
      <div class="vision_title animated d-1s">
        MISSION
      </div>
      <ul class="valuable_list">
        <li class="animated on">
          <div class="text">
            <div class="big_tit animated d-2s">신뢰와 신용을 바탕으로 고객만족을 실현합니다.</div>
            <ul class="main-business__list animated d-3s">
              <li>
                <!--div class="mb10"><img src="@/assets/img/mission01.png" alt="">
                    </div-->
                <span class="main-business__label">Credibility</span>
                <p class="main-business__txt">신뢰와 신용을 바탕으로 <br> 고객만족을 실현합니다.</p>
              </li>
              <li>
                <!--div class="mb10"><img src="@/assets/img/mission02.png" alt="">
                    </div-->
                <span class="main-business__label">Innovation</span>
                <p class="main-business__txt">프로세스를 혁신하여 <br> 새로운 가치 창출을 도모합니다.</p>
              </li>
              <li>
                <!--div class="mb10"><img src="@/assets/img/mission03.png" alt="">
                    </div-->
                <span class="main-business__label">Quality</span>
                <p class="main-business__txt">고품질의 서비스로 고객의 <br> 지속가능한 성장을 지원합니다.</p>
              </li>
            </ul>
          </div>
          <div class="vi_im"><img src="@/assets/img/img_about02.png" alt=""></div>
        </li>
      </ul>
      <div class="vision_title animated d-1s">
        VISION
      </div>
      <ul class="valuable_list">
        <li class="animated on">
          <div class="text">
            <div class="big_tit animated d-2s">가치 향상에 기여하는 혁신적인 경영지원플랫폼을 제공합니다.</div>
            <ul class="main-business__list animated d-3s">
              <li>
                <span class="main-business__label">사람중심</span>
                <p class="main-business__txt">임직원의 다양성을 존중하고,<br>
                  협력과 소통을 통해 회사의 성장과 발전을 지속합니다.</p>
              </li>
              <li>
                <span class="main-business__label">도전과 혁신</span>
                <p class="main-business__txt">전문지식과 IT 기술 그리고 열정으로<br>
                  끊임없이 도전하고 혁신하겠습니다.</p>
              </li>
              <li>
                <span class="main-business__label">동반 성장</span>
                <p class="main-business__txt">이익 창출만이 아닌 기업 시민으로서<br>
                  사회적 책임을 다하겠습니다.</p>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <div class="sub_wrap gray_bg">
      <div class="ci_inner">
        <div class="sub_wrap_tit align-c">
          <div class="eg animated">CI&BI</div>
          <div class="sub animated d-1s">
            이트너스는 ‘영원한, 불멸의’라는 뜻의 ‘eternal’과 ‘동반자’라는 의미를 가진 ‘partners’의 합성어로<div class="hide_1023"></div>
            클라이언트의 영원한 동반자가 되겠다는 의미를 담고 있습니다.
          </div>
        </div>
        <div class="ci_logo animated">
          <div class="ci_tit">Symbol Mark</div>
          <div class="logo_box">
            <div class="item item01">
              <div class="img">
                <img src="@/assets/img/ci_logo01_pc.png" class="hide_767" alt="">
                <img src="@/assets/img/ci_logo01.png" class="hide-default show_767" alt="">
              </div>
            </div>
            <div class="item item02">
              <div class="img">
                <img src="@/assets/img/ci_logo02_pc.png" class="hide_767" alt="">
                <img src="@/assets/img/ci_logo02.png" class="hide-default show_767" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="ci_color animated">
          <div class="ci_tit">COLOR</div>
          <div class="sub animated d-1s">
            주황색은 신뢰와 긍정적 에너지를, 다양한 색이 균등하게 섞여 만들어진 검정색은 완벽한 전문적인 이미지를 의미합니다.
            <br>최고의 성공 파트너로서 신뢰를 바탕으로 기업에 필요한 모든 서비스를 제공하겠다는 의지를 담고 있습니다.
          </div>
          <ul class="color_box inline_wrap">
            <li class="orange">
              <div class="tit">Orange</div>
              <div class="right">
                <table>
                  <tbody>
                    <tr>
                      <th>HEX</th>
                      <td>#FF6D2D</td>
                    </tr>
                    <tr>
                      <th>RGB</th>
                      <td>255/109/45</td>
                    </tr>
                    <tr>
                      <th>CMYK</th>
                      <td>0/71/86/0</td>
                    </tr>
                    <tr>
                      <th>PANTONE</th>
                      <td>Orange 021 U</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li class="gray mr0">
              <div class="tit">Black</div>
              <div class="right">
                <table>
                  <tbody>
                    <tr>
                      <th>HEX</th>
                      <td>#333132</td>
                    </tr>
                    <tr>
                      <th>RGB</th>
                      <td>51/49/50</td>
                    </tr>
                    <tr>
                      <th>CMYK</th>
                      <td>69/64/61/59</td>
                    </tr>
                    <tr>
                      <th>PANTONE</th>
                      <td>P 179-15C</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--//ci 소개 -->
    <!-- 브랜드 소개 -->
    <div class="sub_wrap">
      <div class="company_inner">
        <div class="sub_wrap_tit align-c">
          <div class="eg animated">이트너스 브랜드</div>
        </div>
        <ul class="group_info">
          <li class="animated">

            <ul class="group_logo folg inline_wrap">
              <div class="ci_tit">영문BI</div>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo01.png" alt="쉐어드서비스"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo02.png" alt="페이롤"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo03.png" alt="비딩"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo04.png" alt="하우징"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo05.png" alt="몰"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo06.png" alt="무빙"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo07.png" alt="릴로케이션"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo08.png" alt="숍"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo09.png" alt="감동타임"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo10.png" alt="efs"></div>

              </li>
              <li>
                <div class="img"><img src="@/assets/img/bi_logo21.png" alt="esop"></div>

              </li>
            </ul>
            <ul class="group_info">
              <li class="animated">
                <ul class="group_logo folg inline_wrap">
                  <div class="ci_tit">국문BI</div>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo11.png" alt="쉐어드서비스"></div>
                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo12.png" alt="페이롤"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo13.png" alt="비딩"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo14.png" alt="하우징"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo15.png" alt="몰"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo16.png" alt="무빙"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo17.png" alt="릴로케이션"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo18.png" alt="숍"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo19.png" alt="감동타임"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo20.png" alt="efs"></div>

                  </li>
                  <li>
                    <div class="img"><img src="@/assets/img/bi_logo22.png" alt="이솝"></div>

                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>
<style>

</style>
<script>
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
const ethDomain = ApiConfig.ethDomain

export default {
  name: 'CompanyOverview',
  created() {
    const vm = this

  },
  mounted() {
    const vm = this
  },
  methods: {
  },
  data: function () {
    const vm = this
    return {

    }
  }
}
</script>
