<template>
    <kendo-window 
        ref="securityHistoryWindowRef" 
        id="changeIdAndEmailWindow" 
        :title="'다운로드 사유 입력 팝업'" 
        :width="'500px'"
        :modal="true" 
        :visible="false"
        style="z-index: 10004; transform: scale(1); opacity: 1;"
        @open="openWindow" 
        @close="closeWindow">

        <template v-if="etnersSecurity.isOpen">
            <div class="pop-container">
                <ul class="w100">
                    <li>
                        <div class="mtb10 txt_c"><b>다운로드 하는 사유를 선택하세요.</b></div>
                        <div class="pd15 contents_wrap k-desc">
                            <kendo-dropdownlist
                                ref="reasonRef"
                                :data-source="$store.state.codeRequestStore.search.combobox.downloadReasonCd.data"
                                :data-text-field="'cdNmTx'"
                                :data-value-field="'cdId'" 
                                :option-label="'다운로드 사유'"
                                v-model="etnersSecurity.reason" 
                                maxlength="300" 
                                :style="'width: 100%;'">
                            </kendo-dropdownlist>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="pop-container">
                <dd>
                    <label>성명</label>
                    <ul>
                      <li>
                        <kendo-maskedtextbox
                                ref="userNmKoTxRef"
                                id="userNmKoTxInput"
                                :style="'width: 100%;'"
                                maxlength="20"
                                v-model="etnersSecurity.userNm"
                                placeholder="이름을 입력해주세요"
                        >
                        </kendo-maskedtextbox>
                      </li>
                    </ul>
                  </dd>
            </div>
            <div class="button_area w60 mb15">
                <ul>
                    <li>
                        <button class="large_btn ml5" @click="$refs.securityHistoryWindowRef.kendoWidget().close()">닫기</button>
                    </li>
                    <li>
                        <button class="large_btn orange ml5" @click="onClickSavePrivacyHistory">저장</button>
                    </li>
                </ul>
            </div>
        </template>
    </kendo-window>
</template>

<script>
import EtnersCommonUtil from '../common/etners.common.util.js'

export default {
    name: 'SelectDownloadReasonWindow',
    mounted() {
        const vm = this
        vm.etnersSecurity.openSecurityHistoryWindow = function () {
            vm.$refs.securityHistoryWindowRef.kendoWidget().center().open()
        }
    },
    methods: {
        openWindow() {
            this.etnersSecurity.isOpen = true
        },
        closeWindow() {
            this.etnersSecurity.isOpen = false
            this.etnersSecurity.reason = null
            this.etnersSecurity.userNm = null
            this.etnersSecurity.apiUrl = null
            this.etnersSecurity.callbackMethods = null

        },
        async onClickSavePrivacyHistory() {
            const vm = this

            function validation() {
                if (EtnersCommonUtil.isEmpty(vm.etnersSecurity.reason)) {
                    kendo.alert('다운로드 하는 사유를 선택해주세요.')
                    return false
                }
                if (EtnersCommonUtil.isEmpty(vm.etnersSecurity.userNm)) {
                    kendo.alert('본인의 이름을 입력 해주세요.')
                    return false
                }
                return true
            }

            if (validation()) {
                await vm.etnersSecurity.insertPersonalInfoHistoryForExcel().then(function (response) {
                    if (response.data.resultStatus.messageCode === '2000') {
                        vm.etnersSecurity.callbackMethods()
                    } else {
                        kendo.alert(response.data.resultStatus.messageText)
                    }
                })

                vm.$refs.securityHistoryWindowRef.kendoWidget().close()
            }
        },

    },
    data() {
        
        const etnersSecurity = window.etnersSecurityEventBus
        return {
            etnersSecurity,
        }
    },
}
</script>

<style scoped></style>
