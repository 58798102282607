
import SelectDownloadReasonWindow from '@/components/SelectDownloadReasonWindow.vue'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'


function install (Vue, options = {}) {

  let Security = {
    $vm: null,
    init (vm, on = true) {
        this.$vm = vm
        this.$vm.reason = null
        this.$vm.userNm = null
    },
    open(){
      this.$vm.openSecurityHistoryWindow()
    },
    setApiUrl(apiUrl){
      let url = document.createElement('a');
      url.href = apiUrl
      this.$vm.apiUrl = location.host === url.host ? null : url.pathname
      url.remove()
    },
    setCallbackMethods(callbackFunction){
      this.$vm.callbackMethods = callbackFunction
    },
  }
  const securityEventBus = new Vue({
    data: {
      isOpen : false,
      reason : null,
      apiUrl : null,
      callbackMethods : null,
      insertPersonalInfoHistoryForExcel : function insertPersonalInfoHistoryForExcel () {
        const securityVm = this
        let params = {
          loginUserToken: sessionStorage.adminLoginUserToken,
          mainUrl: window.location.href, 
          reason: securityVm.reason,
          apiUrl: securityVm.apiUrl,
          userNm: securityVm.userNm,
          excelYn: "Y"
        }
        
        return ApiUtil.post(
          `${ApiConfig.ethDomain}/v1/eth/security/insertPersonalInfoHistory.json`, params)
      },
    }
  })

  window.etnersSecurityEventBus = securityEventBus
  Security.init(securityEventBus)

  Vue.component('SelectDownloadReasonWindow', SelectDownloadReasonWindow)


  //인스턴스 메서드 추가

  Vue.prototype.$saveSecurityHistoryForExcel = function (url = null, callback){
    Security.open()
    Security.setApiUrl(url)
    Security.setCallbackMethods(callback)
  }

}

export default {
  install
}
